import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TripComponent} from './trip/trip.component';
import {HealthCheckComponent} from './trip/healthCheck.component';
import {RequestComponent} from './request/request.component';

const routes: Routes = [
  {
    path: '',
    component: RequestComponent
  },
  {
    path: '?ope&p',
    component: RequestComponent,
  },
  {
    path: 'dsvn',
    component: TripComponent
  },
  {
    path: 'healthcheck',
    component: HealthCheckComponent
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
