import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormModel} from '../../share/model/FormModel';
import {MapsAPILoader} from '@agm/core';
import {TrainStation} from '../../share/constants/location.constants';
import {VEHICLE} from '../../share/constants/vehicle.constants';
import {AppService} from '../app.service';
import {ILocation} from '../../share/model/ILocation';
import {ActivatedRoute} from '@angular/router';
import * as moment from 'moment';
import {AccountKit, AuthResponse} from 'ng2-account-kit';
import {DialogService} from '../services/dialog.service';
import {DialogData} from '../../share/dialog-data';
import {ObjModal} from '../../share/ObjModal';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormFbKit} from '../../share/model/FormFbKit';
import {AccountKitWrapper} from 'ng2-account-kit/dist/ng2-account-kit';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';


@Component({
  selector: 'app-root',
  templateUrl: './trip.component.html',
  styleUrls: ['./trip.component.css'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'vi-VN' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
})
export class TripComponent implements OnInit, OnDestroy {
  public optionButton: number;
  public regexFormGroup: FormGroup;
  public mobiPattern = '^(09|01|03|08[2|6|8|9])+[0-9]{8}$';
  constructor(
    private mapsAPILoader: MapsAPILoader,
    private appService: AppService,
    protected activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private formBuilder: FormBuilder
  ) {
    this.regexFormGroup = this.formBuilder.group({
      pickLoca: ['', [Validators.required]],
      dropLoca: ['', [Validators.required]],
      date: ['', [Validators.required]],
      pickLoca1: ['', [Validators.required]],
      dropLoca1: ['', [Validators.required]],
      vehicle: ['', [Validators.required]],
      vehicle1: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.pattern(this.mobiPattern)
        , Validators.minLength(10), Validators.maxLength(11)]],
    });

    if (navigator) {
      // IOS
      if (navigator.userAgent.match(/iPhone/i) || (navigator.userAgent.match(/iPod/i))) {
        this.url = 'https://itunes.apple.com/us/app/fastgo-mobi-driver/id1394387352';
      } else if (navigator.userAgent.match(/android/i)) {
        this.url = 'https://play.google.com/store/apps/details?id=vn.mpos.fastgo.driver';
      } else {
        this.url = 'https://play.google.com/store/apps/details?id=vn.mpos.fastgo.driver';
      }
    }
  }

  title = 'fastgo-web';
  formModel: FormModel;
  lstAirport: Array<TrainStation>;
  lstVehicle: any;
  mobile: string;
  // location current
  latitude1: number;
  longitude1: number;
  // location dropOff
  address2: string;
  latitude2: number;
  longitude2: number;
  // location return
  latitude3: number;
  longitude3: number;

  address4: string;
  latitude4: number;
  longitude4: number;
  // location dropOff
  latitude5: number;
  longitude5: number;
  // location return
  latitude6: number;
  longitude6: number;
  zoom: number;
  showAddReturn: boolean;
  transportType = 1 as number;
  geoCoder: any;

  strPickUpDateTime: string;
  strReturnDateTime: string;
  strPickUpDateTime1: string;
  strReturnDateTime1: string;
  // date time
  pickUpDate: any;
  pickUpTime: any;
  returnDate: any;
  returnTime: any;
  // date time 1
  pickUpDate1: any;
  pickUpTime1: any;
  returnDate1: any;
  returnTime1: any;
  url: string;
  currenTimeFrom: any;
  todaydate: Date = new Date();

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.appService.getTrainStation().subscribe(
      (dataRes) => {
        this.lstAirport = dataRes.body;
        console.log('RES: get lst train station ==>', dataRes.body);
      },
      (err) => {
        console.log('ERR: can not get lst train station !', err);
      });
    this.showAddReturn = false;
    // this.lstAirport = this.transportType === 1 ? TRAIN_STATION : AIRPORT;
    this.lstVehicle = VEHICLE;
    this.formModel = new FormModel();
    this.formModel.pickUpLocation = new ILocation();
    this.formModel.pickUpLocation1 = new ILocation();
    this.formModel.dropOffLocation = new ILocation();
    this.formModel.dropOffLocation1 = new ILocation();
    this.formModel.returnLocation = new ILocation();
    this.formModel.returnLocation1 = new ILocation();
    this.setCurrentLocation();
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
    });
    // Get Token params from url Verify and DeCode
    this.activatedRoute.queryParams.subscribe(params => {
      // console.log('token', params);
      if (params.token) {
        this.verifyToken(params);
        console.log('params not null');
      } else {
        this.initForm();
        console.log('params null');
      }
    });
    AccountKit.init(
      {
        appId: '246982212704598',
        state: '35d23f3fb6506edd6dcb38cc49856aa7',
        version: 'v1.0'
      }
    );
    AccountKitWrapper.init(
      {
        appId: '246982212704598',
        state: '35d23f3fb6506edd6dcb38cc49856aa7',
        version: 'v1.0'
      }
    );
  }
  initForm() {
    this.formModel.returnTrip = true;
    this.optionButton = 1;
    this.formModel.fullName = '';
    this.mobile = '';
    this.formModel.email = '';
  }
  // binding data from url to formModel
  dataBindingToForm(params) {
    this.formModel.kindTrip = params.kind_trip;
    this.formModel.returnTrip = params.return_trip;
    this.formModel.kindTrip = params.kind_trip;
    // this.formModel.kindTrip = 0;
    if (this.formModel.kindTrip === 1) {
      this.optionButton = 1;
    } else if (this.formModel.kindTrip === 2) {
      this.optionButton = 2;
    } else if (this.formModel.kindTrip === 0) {
      this.optionButton = 1;
    }
    console.log('kind trip: ', params.kind_trip);
    this.formModel.fullName = params.name;
    this.formModel.phoneNumber = params.mobile;
    this.mobile = params.mobile;
    // this.pickUpTime = params.depart_date;
    this.currenTimeFrom = params.depart_date;
    this.formModel.email = params.mail;
    this.address2 = params.departure_code;
    this.address4 = params.arrival_code;
    // tslint:disable-next-line:radix
    const test = (params.depart_date - parseInt(String(params.depart_date / 86400)) * 86400);
    const hours = Math.floor((test / ( 60 * 60)) % 24) - (new Date().getTimezoneOffset() / 60);
    const minutes = Math.floor((test / (60)) % 60);
    this.currenTimeFrom = {hour: hours, minute: minutes};
    // tslint:disable-next-line:max-line-length no-unused-expression
    const defaultTime =  ((hours) < 10 ? ( + '0' + ''  + (hours)) : (hours)) + ':' +  ((minutes) < 10 ? ( + '0' + '' + (minutes)) : (minutes) );
    console.log('hfsjkdfhsd', this.currenTimeFrom, defaultTime);
    this.pickUpTime1 = defaultTime;
    this.pickUpTime = defaultTime;
    // Đi ga tàu
    this.formModel.dropOffLocation.address = params.departure_code;
    this.address2Change(params.departure_code, 2);
    this.pickUpDate =  moment.unix(Number(params.depart_date)); // timestamp type (seconds)
    // Đón ga tàu
    this.formModel.pickUpLocation1.address = params.arrival_code;
    this.address2Change(params.arrival_code, 4);
    this.pickUpDate1 = moment.unix(Number(params.arrival_date)); // timestamp type (seconds)
  }

  changeOptionButton(value: number) {
    this.optionButton = value;
  }
  ConvertDatime(date, time) {
    let str: string;
    if (date && time) {
      str = date.toString().slice(0, 16) + time  + date.toString().slice(24, 33);
    }
    return str;
  }

  initFormModel() {
    const size = this.mobile.length;
    this.formModel.phoneNumber = this.mobile.slice(1, size);
    this.strPickUpDateTime = this.ConvertDatime(this.pickUpDate, this.pickUpTime);
    this.strReturnDateTime = this.ConvertDatime(this.returnDate, this.returnTime);
    this.strPickUpDateTime1 = this.ConvertDatime(this.pickUpDate1, this.pickUpTime1);
    this.strReturnDateTime1 = this.ConvertDatime(this.returnDate1, this.returnTime1);
    /*console.log('time picker output: ', this.pickUpDate.toString());
    console.log('str picker output : ', this.strPickUpDateTime);
    console.log('str picker output 1: ', this.strPickUpDateTime1);*/
    // if (this.latitude1 !== undefined && this.latitude5 !== undefined) {
    //    this.formModel.kindTrip = 0;
    // } else if (this.formModel.pickUpLocation.latitude === undefined ) {
    //   this.formModel.kindTrip = 2;
    // }
    // if (this.formModel.dropOffLocation1.latitude === undefined) {
    //   this.formModel.kindTrip = 1;
    // }
    this.formModel.pickUpLocation.latitude = this.latitude1 + '';
    this.formModel.pickUpLocation.longitude = this.longitude1 + '';
    this.formModel.dropOffLocation.latitude = this.latitude2 + '';
    this.formModel.dropOffLocation.longitude = this.longitude2 + '';
    this.formModel.returnLocation.latitude = this.latitude3 + '';
    this.formModel.returnLocation.longitude = this.longitude3 + '';
    this.formModel.pickUpDateTime = new Date(this.strPickUpDateTime);
    this.formModel.returnDateTime = new Date(this.strReturnDateTime);
    this.formModel.pickUpLocation1.latitude = this.latitude4 + '';
    this.formModel.pickUpLocation1.longitude = this.longitude4 + '';
    this.formModel.dropOffLocation1.latitude = this.latitude5 + '';
    this.formModel.dropOffLocation1.longitude = this.longitude5 + '';
    this.formModel.returnLocation1.latitude = this.latitude6 + '';
    this.formModel.returnLocation1.longitude = this.longitude6 + '';
    this.formModel.pickUpDateTime1 = new Date(this.strPickUpDateTime1);
    this.formModel.returnDateTime1 = new Date(this.strReturnDateTime1);
  }

  disabled() {
    if (this.optionButton === 1) {
      // tslint:disable-next-line:max-line-length
      return this.formModel.pickUpLocation.longitude ||  !this.pickUpDate || !this.pickUpTime || !this.formModel.pickUpLocation.address
        || !this.formModel.dropOffLocation.address || !this.formModel.kindVehicle1 || !this.mobile;
  } else {
      return this.formModel.pickUpLocation1.latitude || !this.pickUpDate1 || !this.pickUpTime1 || !this.formModel.pickUpLocation1.address
        || !this.formModel.dropOffLocation1.address || !this.formModel.kindVehicle3 || !this.mobile;
    }
  }

  // if (this.optionButton === 2) {
    //   // tslint:disable-next-line:max-line-length
    //   return !this.pickUpDate1 || !this.pickUpTime1 || !this.formModel.pickUpLocation1.address
    //     || !this.formModel.dropOffLocation1.address || !this.formModel.kindVehicle3 || !this.mobile;
    // }
    // if (this.optionButton === 0) {
    //   return !this.pickUpDate || !this.pickUpTime || !this.formModel.pickUpLocation.address || !this.formModel.dropOffLocation.address
    //     || !this.formModel.kindVehicle1 || !this.pickUpDate1 || !this.pickUpTime1 || !this.formModel.pickUpLocation1.address
    //     || !this.formModel.dropOffLocation1.address || !this.formModel.kindVehicle3 || !this.mobile;
    // }

  setCurrentLocation() {
    this.latitude1 = 20.994892;
    this.longitude1 = 105.861798;
    this.latitude4 = 20.994892;
    this.longitude4 = 105.861798;
    this.zoom = 15;
  }

  getCoordinate(strAddress: string, index: number) {
    if (this.geoCoder === undefined) {
      console.log('...wait for init geoCoder!');
      return;
    } else {
      this.geoCoder.geocode({address: strAddress}, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results[0]) {
          switch (index) {
            case 1:
              this.latitude1 = results[0].geometry.location.lat();
              this.longitude1 = results[0].geometry.location.lng();
              break;
            /*case 2:
              this.latitude2 = results[0].geometry.location.lat();
              this.longitude2 = results[0].geometry.location.lng();
              break;*/
            case 3:
              this.latitude3 = results[0].geometry.location.lat();
              this.longitude3 = results[0].geometry.location.lng();
              break;
            /*case 4:
              this.latitude4 = results[0].geometry.location.lat();
              this.longitude4 = results[0].geometry.location.lng();
              break;*/
            case 5:
              this.latitude5 = results[0].geometry.location.lat();
              this.longitude5 = results[0].geometry.location.lng();
              break;
            case 6:
              this.latitude6 = results[0].geometry.location.lat();
              this.longitude6 = results[0].geometry.location.lng();
          }
        }
      });
    }
  }

  addressChange(val, index: number) {
    this.getCoordinate(val, index);
  }

  // Get latLng trực tiếp khi có sự kiện select trên form
  /*address2Change(val, index: number) {
    this.lstAirport.forEach((a) => {
      if (val === a.code) {
        this.getCoordinate(a.item, index);
      }
    });
  }*/

  // Dùng Khi location.constants đã có latlong
  address2Change(val, index: number) {
    if (this.lstAirport === undefined) {
      console.log('...wait for get lst train station!');
      return;
    } else {
      this.lstAirport.forEach((a) => {
        if (val === a.maGa) {
          if (index === 2) {
            this.formModel.dropOffLocation.address = a.tenGa;
            this.latitude2 = parseFloat(a.latitude);
            this.longitude2 = parseFloat(a.longitude);
            console.log('lat_1: ', this.latitude2);
            console.log('long_1: ', this.longitude2);
          }
          if (index === 4) {
            this.formModel.pickUpLocation1.address = a.tenGa;
            this.latitude4 = parseFloat(a.latitude);
            this.longitude4 = parseFloat(a.longitude);
            console.log('lat_2: ', this.latitude4);
            console.log('long_2: ', this.longitude4);
          }
        }
      });
    }
  }

  toggleAddReturn() {
    this.showAddReturn = !this.showAddReturn;
  }

  menuChange(val: number) {
    this.transportType = val;
    this.ngOnInit();
  }

  // create() {
  //   this.initFormModel();
  //   console.log('FORM SUBMIT ===>', this.formModel);
  //   const phone = this.formModel.phoneNumber;
  //   console.log('phone ===> ', phone);
  //   AccountKitWrapper.login('PHONE', {countryCode: '+84', phoneNumber: phone}).then(
  //     (resLogin: AuthResponse) => {
  //       console.log('RESPONSE LOGIN FB ===> ', resLogin);
  //       const obj = new FormFbKit();
  //       obj.code = resLogin.code;
  //       obj.mobile = phone;
  //       console.log('jkdfgjksfdgh sfdkjghsjkdh ===>', obj.mobile);
  //       const THIS = this;
  //       THIS.appService.checkMobileFacebookKit(obj).subscribe(
  //         (response) => {
  //           console.log('RESPONSE FB CHECK MOBILE ===> ',  response.body);
  //           if (response.body.result) {
  //             THIS.appService.create(THIS.formModel).subscribe(
  //               (res) => {
  //                 console.log('this is success facebook: ' + res);
  //                 console.log('RESPONSE CREATE ===> : ' + JSON.stringify(res.body));
  //                 if (res.body.data !== null) {
  //                   this.openDialog(res.body);
  //                 } else {
  //                   alert('Error: ' + res.body.message);
  //                 }
  //                 console.log('data đặt chuyến : ' + JSON.stringify(res.body.data));
  //               },
  //               (ex) => {
  //                 console.log('this is error: ' + JSON.stringify(ex.body));
  //                 THIS.formModel.errorMessage = 'Create error';
  //               }
  //             );
  //           } else {
  //             THIS.formModel.errorMessage = 'Mobile invalid';
  //           }
  //         },
  //         (error) => {
  //           console.log('facebook kit is error: ' + error);
  //           THIS.formModel.errorMessage = 'Verify facebook error';
  //         }
  //       );
  //     },
  //     (error: any) => {
  //       console.log('Error authen fb', error);
  //     }
  //   );
  // }


  create() {
    this.initFormModel();
    console.log(this.formModel);
    this.appService.create(this.formModel).subscribe(
      (res) => {
        // console.log('RESPONSE ĐẶT CHUYẾN ======== : ' + JSON.stringify(res.body));
        const dataRes: DialogData = res.body;
        if (res.body.data !== null) {
          this.openDialog(res.body);
        } else {
          alert('Error: ' + res.body.message);
        }
        // console.log('this is body.data : ' + JSON.stringify(res.body.data));
      },
      (err) => {
        console.log('error đặt chuyến: ' + JSON.stringify(err.body));
      }
    );
  }
  verifyTime() {
      this.create();
    // if ( this.formModel.kindTrip === 2 || this.formModel.kindTrip === 0) {
    //   if ( this.currenTimeFrom.hour > this.pickUpTime.hour) {
    //     alert('Thời gian đón phải sớm hơn giờ đi!');
    //     return;
    //   }
    //   if ( this.currenTimeFrom.hour === this.pickUpTime.hour && this.currenTimeFrom.minute < this.pickUpTime.minute) {
    //     alert('Thời gian đón phải sớm hơn giờ đi!');
    //     return;
    //   } else {
    //     this.create();
    //   }
    // }
  }
  verifyToken(params) {
    this.appService.getPayLoadFromToken(params).subscribe(
      (data) => {
        console.log('this is success: ' + JSON.stringify(data));
        // console.log('this is success: ' + data.body.name);
        this.dataBindingToForm(data.body);
      },
      (error) => {
        console.log('this is error: ' + error);
      }
    );
  }

  openDialog(dialogData: DialogData) {
    const dialogRef = this.dialogService.openDialog(
      dialogData, {disableClose: true});

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('clicked OK');
      } else {
        console.log('clicked Cancel');
      }
    });
  }

  // test popup confirm
  test() {
    const obj1 = new ObjModal();
    obj1.mobile = '52345';

    const obj2 = new ObjModal();
    obj2.mobile = '52345';

    const arr = [obj1, obj2];

    const data = new DialogData();
    data.message = 'sadgf';
    data.code = 1000;
    data.result = true;
    data.data = arr;
    this.openDialog(data);
  }
}

// a3931ee97591f7bd5618771a1352f8a7f6bb987d17dc6c3240ca71f73cd6f9e6
