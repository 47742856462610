import {Component, Inject, OnInit} from '@angular/core';
import {DialogData} from '../../share/dialog-data';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {AppService} from '../app.service';
import {MatSnackBar} from '@angular/material';
import {OtpDialogComponent} from '../request/otp-dialog/otp-dialog.component';
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  name;
  error;
  data;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dataRes: DialogData,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<DialogComponent>,
    private appService: AppService) {
  }

  panelOpenState: boolean;
  // formConfirm: FormConfirm;
  conFirm() {
    this.close();
    const arr = [];
    for (const i of this.dataRes.data) {
      arr.push(i.requestKey);
    }
    // for (const i of arr) {
    //   console.log('request key = ', i);
    // }

    console.log('-----DATA CONFIDM ---> ', this.dataRes.data);
    this.appService.confirmInfo(arr).subscribe(
      (res) => {
        alert('Đặt Lịch thành công!!');
       /* const dialogRef = this.dialog.open(OtpDialogComponent, {
          width: 'auto',
          data: {name: this.name = 'Xác nhận số điện thoại', error: this.error = 2, data: this.data = this.dataRes.data, mode: 'open' },
        });
        // console.log( this.data);
        dialogRef.afterClosed().subscribe(result => {
          this.error = result;
        });*/
        // console.log('confirm success', res);
        this.data = res.body;
      },
      (err) => {
        // console.log('error confirm:', err);
      }
    );
  }
  getTotalCost() {
    // return this.dataRes.data.map(t => t.cost).reduce((acc, value) => acc + value, 0);
    this.dataRes.data.map(t => {
      // console.log( t.fare += t.fare);
    });
  }
  cancel() {
    this.close();
  }
  close() {
    this.dialogRef.close(true);
  }
  changeIcon() {
    this.panelOpenState = !this.panelOpenState;
  }
  ngOnInit() {
  }
}
