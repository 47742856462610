import { ILocation} from './ILocation';

export class FormRequest {
  name?: string;
  mobi?: string;
  email?: string;
  discountCode?: string;
  noteOperator?: string;

  pickUpLocation?: ILocation;
  dropOffLocation?: ILocation;
  pickUpDateTime?: Date;
  vehicleType?: string;

  errorMessage?: string;
  // constructor() {
  //   this.name = '';
  //   this.mobi = '';
  //   this.email = '';
  //   this.discountCode = '';
  //
  // }
}
