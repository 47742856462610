import {Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LatLng, LatLngLiteral, MapsAPILoader} from '@agm/core';
import {VEHICLE} from '../../share/constants/vehicle.constants';
import {AppService} from '../app.service';
import {ILocation} from '../../share/model/ILocation';
import {ActivatedRoute} from '@angular/router';
import * as moment from 'moment';
import {AccountKit, AuthResponse} from 'ng2-account-kit';
import {DialogService} from '../services/dialog.service';
import {DialogData} from '../../share/dialog-data';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FormFbKit} from '../../share/model/FormFbKit';
import {FormRequest} from '../../share/model/FormRequest';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {MatDialog, MatSnackBar} from '@angular/material';
import {OtpDialogComponent} from './otp-dialog/otp-dialog.component';
import { Router } from '@angular/router';

export interface OtpDialogData {
  name: boolean;
  error: number;
  data: any;
  mode: string;
}
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'request',
  templateUrl: 'request.component.html',
  styleUrls: ['./request.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'vi-VN' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
})


export class RequestComponent implements OnInit, OnDestroy {



  // public searchElementRef: ElementRef;
  constructor(
    private mapsAPILoader: MapsAPILoader,
    private appService: AppService,
    protected activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private formBuilder: FormBuilder,
    private ngZone: NgZone,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    public router: Router,
  ) {

    this.regex = this.formBuilder.group({

        name: [''],
        pickUpLocation: [this.findFromtAdress(), [Validators.required]],
        dropOffLocation: [this.findAdress(), [Validators.required]],
        date: [this.pickUpDate, [Validators.required]],
        time: [this.pickUpTime = this.defaultTime , [Validators.required]],
        vehicle: [this.lstVehicle, [Validators.required]],
        phone: [this.phone, [Validators.required, Validators.pattern(this.mobiPattern)
          , Validators.minLength(10), Validators.maxLength(11)]],
    },
    {
      // validator: MustMatch('date', 'vehicle', 'phone', 'pickUpLocation', 'dropOffLocation')
    }
    );




    if (navigator) {
      this.url = 'https://fastgo.mobi/khach';
      // IOS
      // if (navigator.userAgent.match(/iPhone/i) || (navigator.userAgent.match(/iPod/i))) {
      //   this.url = 'https://itunes.apple.com/us/app/fastgo-mobi-driver/id1394387352';
      // } else if (navigator.userAgent.match(/android/i)) {
      //   this.url = 'https://play.google.com/store/apps/details?id=vn.mpos.fastgo.driver';
      // } else {
      //   this.url = 'https://play.google.com/store/apps/details?id=vn.mpos.fastgo.driver';
      // }
    }

  }

  public searchControl: FormControl;
  public regex: FormGroup;
  public mobiPattern = '^(09|01|03|08|07|05|)+([0-9]{8,9})$';
  // @ViewChild('search', {static: true})
  @ViewChild('search', {static: true}) searchElementRef: ElementRef;
  @ViewChild('searchFrom', {static: true}) searchElementRefFrom: ElementRef;
  formRequest: FormRequest;
  vehicleTypeDefault = 'JUST_GO';
  lstVehicle: any;
  phone: string;
  // location current
  latitude1: number;
  longitude1: number;
  // location dropOff
  latitude2: number;
  longitude2: number;
  zoom: number;
  geoCoder: any;
  strPickUpDateTime: string;
  pickUpDate =  moment().startOf('day');
  pickUpTime: any;
  url: string;
  map;
  public latitude: number;
  public longitude: number;
  date: any;
  time: any;
  timeInval = true;
  name;
  error;
  data: any;
  dir = undefined;
  todaydate: Date = new Date();
  isOperator: boolean;
  isChecked: boolean;
 private d1 = new  Date();
  private d = new Date(this.d1.getTime() + (30 * 60 * 1000)).toString();
  // tslint:disable-next-line:max-line-length no-unused-expression
  private defaultTime =  ((new Date(this.d).getHours()) < 10 ? ( + '0' + ''  + (new Date(this.d).getHours())) : (new Date(this.d).getHours())) + ':' +  (( new Date(this.d).getMinutes()) < 10 ? ( + '0' + '' + ( new Date(this.d).getMinutes())) : ( new Date(this.d).getMinutes()) );
  submitted = false;
  public renderOptions = {
    suppressMarkers: true,
  };

  public markerOptions = {
    origin: {
      icon: '.',
    },
    destination: {
      icon: '.',
      infoWindow: `Điểm trả khách`
    },
  };
  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    // this.latitude = 20.994892;
    // this.longitude = 105.861798;
    // console.log("this time: " , this.d)
    console.log('this time: ' , this.pickUpTime) ;
    this.setCurrentPosition();
    // this.setLocation();
    this.formRequest = new FormRequest();
    this.lstVehicle = VEHICLE;
    this.formRequest.pickUpLocation = new ILocation();
    this.formRequest.dropOffLocation = new ILocation();
    this.searchControl = new FormControl();
    this.isChecked = false;
    // this.setCurrentPosition();

    if (this.checkQueryParam() === true) {
      this.isOperator = true;
    }

    // this.map = new google.maps.Map(document.getElementById('map'), {
    //   zoom: 4,
    //   center: {lat: -25.363, lng: 131.044}
    // });
    // tslint:disable-next-line:only-arrow-functions

    this.findAdress();
    this.findFromtAdress();

    setTimeout(() => {
      // console.log("heh");
      // this.getAddressByLatLng(this.latitude1, this.longitude1);
    }, 1000);

  }
  eventCheck(completed: boolean) {
    this.isChecked = completed;
    console.log('check boox:---> ' + this.isChecked);
  }
  get f() { return this.regex.controls; }
  onSubmit() {
    this.submitted = true;

    if ( this.regex.invalid && this.phone == null || this.regex.invalid && this.phone === '' || this.f.phone.errors ) {
      alert('Bạn chưa nhập Số điện thoại hoặc Số điện thoại không đúng!!\n\n');
      return;
    }
    if (this.regex.invalid && this.pickUpTime == null) {
      alert('Bạn chưa nhập giờ đi!!\n\n');
      return;
    }
    const timeCheck = new Date();
    const timeCheckFuture = new Date(timeCheck.getTime() + (30 * 60 * 1000)).toString();
    // tslint:disable-next-line:max-line-length no-unused-expression
    const timeNow =  ((new Date(timeCheckFuture).getHours()) < 10 ? ( + '0' + ''  + (new Date(timeCheckFuture).getHours())) : (new Date(timeCheckFuture).getHours())) + ':' +  (( new Date(timeCheckFuture).getMinutes()) < 10 ? ( + '0' + '' + ( new Date(timeCheckFuture).getMinutes())) : ( new Date(timeCheckFuture).getMinutes()) );
    console.log('time now ', this.pickUpTime, timeNow);
    console.log('date now ', this.pickUpDate.date(), new Date().getDate());
    // tslint:disable-next-line:max-line-length
    if ( this.pickUpDate.date() === new Date().getDate() && this.pickUpDate.month() === new Date().getMonth() && this.pickUpTime + '00:30'  < timeNow) {
      this.timeInval = false;
      console.log(this.timeInval);
      alert('Giờ bạn chọn phải sau giờ hiện tại ít nhất 30 phút!!\n\n');
      return;

    }
    if ( this.regex.invalid && this.formRequest.vehicleType == null ) {
      alert('Bạn chưa chọn loại xe!!\n\n');
      return;
      // tslint:disable-next-line:max-line-length align
    } if (this.regex.invalid && this.formRequest.dropOffLocation == null || this.regex.invalid && this.formRequest.dropOffLocation.address == null ) {
      alert('Bạn chưa chọn điểm đến!!\n\n');
      return;
      // tslint:disable-next-line:max-line-length align
    } if ( this.regex.invalid && this.formRequest.pickUpLocation == null || this.regex.invalid && this.formRequest.pickUpLocation.address == null ) {
      alert('Bạn chưa chọn điểm đi!!\n\n');
      return;
    }
    // Check: nếu vận hành đặt lịch hộ khách --> ko check otp
    console.log('check query: ', this.checkQueryParam());
    if (this.checkQueryParam() === true) {
      console.log('--->>>>>> Operator create trip');
      this.operatorCreateReq();
      console.log('<<<<---- Operator create req done!');
    } else {
      this.openOtpDialog();
    }
    console.log('Done !!!');
  }
  taoHoChuyen() {
    if ( this.regex.invalid && this.phone == null || this.regex.invalid && this.phone === '' || this.f.phone.errors ) {
      alert('Bạn chưa nhập Số điện thoại hoặc Số điện thoại không đúng!!\n\n');
      return;
    }
    this.initRequest();
    this.appService.sentRequest(this.formRequest).subscribe(
      (res) => {
        // console.log('RESPONSE ĐẶT HỘ CHUYẾN ======== : ' + JSON.stringify(res.body));
        if (res.body.data !== null) {
          alert('Gửi yêu cầu thành công !');
          // this.openDialog(res.body);
        } else {
          alert('Lỗi: ' + res.body.message);
        }
      },
      (err) => {
        console.log('Lỗi đặt chuyến: ' + JSON.stringify(err.body));
      }
    );
  }
  public setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.latitude1 = position.coords.latitude;
        this.longitude1 =   position.coords.longitude;
        if (this.latitude1 == null || this.longitude1 == null ) {
         this.latitude1 = 20.994892;
         this.longitude1 = 105.861798;
         this.latitude = 20.994892;
         this.longitude = 105.861798;
        }
        this.zoom = 16;
        // console.log('location' , this.latitude, this.longitude);
        this.mapsAPILoader.load().then(() => {
        this.geoCoder = new google.maps.Geocoder();
        this.getAddressByLatLng(position.coords.latitude, position.coords.longitude);
        });
        // setTimeout(() => {
        //     }, 100);

      });
    }
  }

  // tslint:disable-next-line:variable-name
  getAddressByLatLng(_lat: number, _lng: number) {

    // console.log('Finding Address');
    if (navigator.geolocation) {
      // this.geoCoder = new google.maps.Geocoder();
      // const latlng = new google.maps.LatLng(_lat, _lng);
      const latlng = {lat: _lat, lng: _lng};
      // const request = { latLng: latlng };
      this.geoCoder.geocode({location: latlng}, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const result = results[0];
          const rsltAdrComponent = results[0].formatted_address;
          // console.log(results[0].formatted_address)
          this.formRequest.pickUpLocation.address = rsltAdrComponent;
        }
      });
    }
  }



  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms) );
  }

  ConvertDatime(date, time) {
    let str: string;
    if (date && time) {
      str = date.toString().slice(0, 16) + time  + date.toString().slice(24, 33);
    }
    return str;
  }

  initFormRequest() {
    this.strPickUpDateTime = this.ConvertDatime(this.pickUpDate, this.pickUpTime);
    this.formRequest.pickUpLocation.latitude = this.latitude1 + '';
    this.formRequest.pickUpLocation.longitude = this.longitude1 + '';
    this.formRequest.dropOffLocation.latitude = this.latitude2 + '';
    this.formRequest.dropOffLocation.longitude = this.longitude2 + '';
    this.formRequest.pickUpDateTime = new Date(this.strPickUpDateTime);
    const size = this.phone.length;
    this.formRequest.mobi = this.phone;
    // this.onSubmit()
  }

  initRequest() {
    this.strPickUpDateTime = this.ConvertDatime(this.pickUpDate, this.pickUpTime);
    this.formRequest.pickUpLocation.latitude = this.latitude1 !== undefined ? this.latitude1 + '' : null;
    this.formRequest.pickUpLocation.longitude = this.longitude1 !== undefined ? this.longitude1 + '' : null;
    this.formRequest.dropOffLocation.latitude = this.latitude2 !== undefined ? this.latitude2 + '' : null;
    this.formRequest.dropOffLocation.longitude = this.longitude2 !== undefined ? this.longitude2 + '' : null;
    this.formRequest.pickUpDateTime = new Date(this.strPickUpDateTime);
    const size = this.phone.length;
    this.formRequest.mobi = this.phone;
  }

  // disabled() {
  //   return !this.pickUpDate || !this.pickUpTime || !this.formRequest.pickUpLocation.address
  //     || !this.formRequest.dropOffLocation.address || !this.formRequest.vehicleType || !this.phone;
  // }
  findFromtAdress() {
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRefFrom.nativeElement);
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // some details
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          // this.address = place.formatted_address;
          // this.web_site = place.website;
          // this.name = place.name;
          // this.formRequest.pickUpLocation.address = place.address_components[place.address_components.length - 1].long_name;
          // set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.latitude1 = place.geometry.location.lat();
          this.longitude1 = place.geometry.location.lng();
          this.formRequest.pickUpLocation.address = place.formatted_address;
          // console.log(this.latitude1, this.longitude1);
          this.zoom = 16;
          this.getDirection();

        });
      });

    });

  }


  findAdress() {
    this.mapsAPILoader.load().then(() => {
      const autocomplete2 = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete2.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // some details
          const place: google.maps.places.PlaceResult = autocomplete2.getPlace();
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.latitude2 = place.geometry.location.lat();
          this.longitude2 = place.geometry.location.lng();
          this.formRequest.dropOffLocation.address = place.formatted_address;
          // console.log(this.latitude2, this.longitude2);
          this.zoom = 16;
          this.getDirection();

        });
      });

    });

  }
  //  Show direction
  getDirection() {
    // console.log ('address' , this.latitude2 , this.longitude2,  this.latitude1 , this.longitude1);
    this.dir = {
      origin : { lat:  this.latitude1, lng: this.longitude1 },
      destination : { lat: this.latitude2, lng: this.longitude2},
    };
  }


  addressChange(val, index: number) {
    this.getCoordinate(val, index);
  }

  getCoordinate(strAddress: string, index: number) {
    if (this.geoCoder === undefined) {
      // console.log('...wait for init geoCoder!');
      return;
    } else {
      this.geoCoder.geocode({address: strAddress}, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results[0]) {
          switch (index) {
            case 1:
              // this.latitude1 = results[0].geometry.location.lat();
              // this.longitude1 = results[0].geometry.location.lng();
              // console.log('281', this.latitude1 , this.longitude1);
              break;

            case 2:
              // this.latitude2 = results[0].geometry.location.lat();
              // this.longitude2 = results[0].geometry.location.lng();
              // console.log(this.latitude2 , this.longitude2);
              break;
          }
        }
      });
    }
  }
  //   create() {
  //   this.initFormRequest();
  //   console.log('FORM SUBMIT ===>', this.formRequest);
  //   const phone = this.formRequest.mobi;
  //   // console.log('phone ===> ', phone);
  //   AccountKit.login('PHONE', {countryCode: '+84', phoneNumber: phone}).then(
  //     (resLogin: AuthResponse) => {
  //       // console.log('RESPONSE LOGIN FB ===> ', resLogin);
  //       const obj = new FormFbKit();
  //       obj.code = resLogin.code;
  //       obj.mobile = phone;
  //       // console.log('jkdfgjksfdgh sfdkjghsjkdh ===>', obj.mobile);
  //       const THIS = this;
  //       THIS.appService.checkMobileFacebookKit(obj).subscribe(
  //         (response) => {
  //           // console.log('RESPONSE FB CHECK MOBILE ===> ',  response.body);
  //           if (response.body.result) {
  //             THIS.appService.createRequest(THIS.formRequest).subscribe(
  //               (res) => {
  //                 // console.log('this is success facebook: ' + res);
  //                 // console.log('RESPONSE CREATE ===> : ' + JSON.stringify(res.body));
  //                 if (res.body.data !== null) {
  //                   this.openDialog(res.body);
  //                 } else {
  //                   alert('Error: ' + res.body.message);
  //                 }
  //                 // console.log('data đặt chuyến : ' + JSON.stringify(res.body.data));
  //               },
  //               (ex) => {
  //                 // console.log('error: ' + JSON.stringify(ex.body));
  //                 THIS.formRequest.errorMessage = 'Create error';
  //               }
  //             );
  //           } else {
  //             THIS.formRequest.errorMessage = 'Mobile invalid';
  //           }
  //         },
  //         (error) => {
  //           // console.log('facebook kit is error: ' + error);
  //           THIS.formRequest.errorMessage = 'Verify facebook error';
  //         }
  //       );
  //     },
  //     (error: any) => {
  //       // console.log('Error authen fb', error);
  //     }
  //   );
  // }

  openOtpDialog(): void {
    this.initFormRequest();
    console.log('---CHECK PHONE LOCAL---->' + localStorage.getItem(this.phone));
    if (localStorage.getItem(this.phone)) {
      // tslint:disable-next-line:radix
      if ( parseInt(localStorage.getItem(this.phone) + 24 * 60 * 60) > parseInt(new Date().getTime().toString())) {
        this.appService.createRequest(this.formRequest).subscribe(
          (res) => {
            // console.log('RESPONSE ĐẶT CHUYẾN ======== : ' + JSON.stringify(res.body));
            if (res.body.data !== null) {
              this.openDialog(res.body);
            } else {
              alert('Lỗi: ' + res.body.message);
            }
            // console.log('this is body.data : ' + JSON.stringify(res.body.data));
          },
          (err) => {
            console.log('Lỗi đặt chuyến: ' + JSON.stringify(err.body));
          }
        );
      } else {
        const dialogRef = this.dialog.open(OtpDialogComponent, {
          width: 'auto',
          data: {name: this.name = 'Xác nhận số điện thoại', error: this.error = 1, data: this.data = this.formRequest, mode: 'open' },
        });
        // console.log( this.data);
        dialogRef.afterClosed().subscribe(result => {
          this.error = result;
        });
      }
    } else {
      const dialogRef = this.dialog.open(OtpDialogComponent, {
        width: 'auto',
        data: {name: this.name = 'Xác nhận số điện thoại', error: this.error = 1, data: this.data = this.formRequest, mode: 'open' },
      });
      // console.log( this.data);
      dialogRef.afterClosed().subscribe(result => {
        this.error = result;
      });
    }
  }

  // Vận hành đặt lịch hộ khách. (*) pass otp
  operatorCreateReq(): void {
    this.initFormRequest();
    this.appService.createRequest(this.formRequest).subscribe(
      (res) => {
        // console.log('RESPONSE ĐẶT CHUYẾN ======== : ' + JSON.stringify(res.body));
        if (res.body.data !== null) {
          this.openDialog(res.body);
        } else {
          alert('Lỗi: ' + res.body.message);
        }
        // console.log('this is body.data : ' + JSON.stringify(res.body.data));
      },
      (err) => {
        console.log('Lỗi đặt chuyến: ' + JSON.stringify(err.body));
      }
    );
  }
  // check query param
  checkQueryParam(): any {
    let check = false;
    this.activatedRoute.queryParams.subscribe(params => {
      console.log('----->  query: ', params);
      if (params.ope === 'true' && params.p === 'fg_schedule') {
        check = true;
      } else {
        this.router.navigateByUrl('');
      }
    });
    console.log('check query: ', check);
    return check;
  }
  openDialog(dialogData: DialogData) {
    const dialogRef = this.dialogService.openDialog(
      dialogData, {disableClose: true});

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('clicked OK');
      } else {
        console.log('clicked Cancel');
      }
    });
  }

  dragMap() {
    console.log('draw-----');
  }

}

