import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AgmCoreModule} from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';   // agm-direction
import {FormsModule} from '@angular/forms';
import {FmmCmpWebSharedModule} from '../share/shared.module';
import {RecaptchaModule} from 'ng-recaptcha';
import {HttpClientModule} from '@angular/common/http';
import {TripComponent} from './trip/trip.component';
import {HealthCheckComponent} from './trip/healthCheck.component';
import {MaterialModule} from './material.module';
import {
  MatButtonModule,
  MatCardModule,
  MatChipsModule,
  MatDialogModule,
  MatExpansionModule,
  MatTableModule,
} from '@angular/material';
import {DialogService} from './services/dialog.service';
import { DialogComponent } from './dialog/dialog.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import {RequestComponent} from './request/request.component' ;
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatInputModule} from '@angular/material';
import { MatFormFieldModule,  MatDatepickerModule, MatNativeDateModule, MatSnackBarModule } from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import {OtpDialogComponent} from './request/otp-dialog/otp-dialog.component';




@NgModule({
    declarations: [
        AppComponent,
        TripComponent,
        HealthCheckComponent,
        DialogComponent,
        OtpDialogComponent,
        RequestComponent
    ],
  imports: [
    MatSnackBarModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxMaterialTimepickerModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatDialogModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCAwjwCbZbLHoc9r632vWL_NXQbraN9G50',
      libraries: ['places', 'drawing', 'geometry']
    }),
    AgmDirectionModule,
    FormsModule,
    FmmCmpWebSharedModule.forRoot(),
    RecaptchaModule,
    HttpClientModule,
    MatCardModule,
    MatExpansionModule,
    MatChipsModule,
    MatTableModule,
    MaterialModule,
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
  ],
      providers: [DialogService],
      bootstrap: [AppComponent],
    entryComponents: [
      OtpDialogComponent,
      DialogComponent
    ]
})
export class AppModule {
}
