import {Component, Inject, OnInit} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

import {OtpDialogData} from '../request.component';
import {AppService} from '../../app.service';
import {DialogData} from '../../../share/dialog-data';


@Component({
  selector: 'app-otp-dialog',
  templateUrl: './otp-dialog.component.html',
  styleUrls: ['./otp-dialog.component.scss'],
  providers: [
    { provide: DialogData},
  ]
})
export class OtpDialogComponent implements OnInit {
  code = '+84';
  otp;
  text;
  set: number;
  title = 'Xác nhận số điện thoại';
  confirm = 1;
  panelOpenState: boolean;
  formArr = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public dataRes: DialogData,
    // public dataRes: DialogData,
    private appService: AppService,
    public dialogRef: MatDialogRef<OtpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OtpDialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    console.log(this.data.data.mobi);
    console.log( localStorage.getItem(this.data.data.mobi));
    if ( this.data.error === 1) {
      this.set = 1;
    } else {
      this.confirm = 0;
      this.set = 0;
      this.title = 'Đặt chuyến thành công';
      this.text = 'Chúng tôi đã ghi nhận thông tin đặt lịch của bạn và đang thông báo tới các lái xe.';
    }
  }

  getOtpCode() {
      this.appService.checkMobileOTP({ code: this.code, mobile: this.data.data.mobi}).subscribe((res) => {
        if (res.body.code === 1000) {
          // console.log(res);
          this.set = 2;
        } else {
          // console.log(res);
        }
      });
  }

  confirmOTP() {
    this.appService.confirmOTP({ code: this.code, mobile: this.data.data.mobi, otp: this.otp}).subscribe((res) => {
      this.confirm = 0;
      this.set = 0;
      if (res.body.code === 1000) {
        // console.log(res);
        this.appService.createRequest(this.data.data).subscribe(
          // tslint:disable-next-line:no-shadowed-variable
          (value) => {
            // console.log('RESPONSE ĐẶT CHUYẾN ======== : ' + JSON.stringify(res.body));
            if (value.body.code === 1000) {
              localStorage.setItem(this.data.data.mobi, new Date().getTime().toString());
              // console.log('Success!');
              this.dataRes.data = value.body.data;
              this.confirmInfo();
              // this.title = 'Đặt chuyến thành công';
              // this.text = 'Chúng tôi đã ghi nhận thông tin đặt lịch của bạn và đang thông báo tới các lái xe.';
            } else {
              this.title = 'Đặt chuyến thất bại';
              this.text = value.body.message;
            }
            // console.log('this is body.data : ' + JSON.stringify(res.body.data));
          },
          (err) => {
            this.text = res.body.message;
          }
        );
      } else {
        this.title = 'Đặt chuyến thất bại';
        this.text = 'Hãy kiểm tra lại mã OTP';
      }
    });
  }
  close() {
    this.dialogRef.close(true);
  }
  conFirm() {
    this.appService.confirmInfo(this.formArr).subscribe(
      (res) => {
        this.confirm = 0;
        this.set = 0;
        this.title = 'Đặt chuyến thành công';
        this.text = 'Chúng tôi đã ghi nhận thông tin đặt lịch của bạn và đang thông báo tới các lái xe.';
      },
      (err) => {
        console.log('error confirm:', err);
      }
    );
  }
  confirmInfo() {
      const arr = [];
      for (const i of this.dataRes.data) {
        arr.push(i.requestKey);
      }
      console.log(this.dataRes.data);
      this.formArr = arr;
      this.confirm = 3;
      this.title = 'Thông tin chuyến đi';
  }
}
