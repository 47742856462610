import * as moment from 'moment';
import { ILocation} from './ILocation';

export class FormModel {
    kindTrip?: number;
    returnTrip?: boolean;
    fullName?: string;
    phoneNumber?: string;
    email?: string;
    discountCode?: string;
    // Đi ga tàu
    pickUpLocation?: ILocation;
    dropOffLocation?: ILocation;
    pickUpDateTime?: Date;
    kindVehicle1?: string;
    // về 1
    returnLocation?: ILocation;
    returnDateTime?: Date;
    kindVehicle2?: string;
    // Đón ga tàu
    pickUpLocation1?: ILocation;
    dropOffLocation1?: ILocation;
    pickUpDateTime1?: Date;
    kindVehicle3?: string;
    // về 2
    returnLocation1?: ILocation;
    returnDateTime1?: Date;
    kindVehicle4?: string;
    // err
    errorMessage?: string;
}
