import {Timestamp} from 'rxjs';

export class ObjModal {
  id: string;
  requestKey: string;
  name: string;
  mobile: string;
  email: string;
  referCode: string;
  promotionCode: string;
  distance: any;
  pickUpDateTime: string;
  startLocation: string;
  latitude: string;
  longitude: string;
  endLocation: string;
  endLatitude: string;
  endLongitude: string;
  type: string;
  status: string;
  fare: string;
  farePromotion: string;
  tip: string;
  adminLocation: string;
  cellName: string;
  passengerId: string;
  fastgoId: string;
  promotionCodeId: string;
  pickupSurcharge: string;
  fareAmount: string;
  ptiInsuranceAmount: string;
  driverInfoBonus: string;
  driverBonusByCode: string;
  driverBonus: string;
  lstDriverInfo: string;
  promotionInfo: string;
  fareToken: string;
  fareMethod: string;
  transactionType: string;
  cardId: string;
  isPtiInsurance: string;
}
