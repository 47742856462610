import {NgModule} from '@angular/core';

import {
  MatTreeModule,
  MatButtonToggleModule,
  MatInputModule,
  MatAutocompleteModule,
  MatFormFieldModule,
  MatCardModule,
  MatToolbarModule,
  MatSelectModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatMenuModule,
  MatExpansionModule,
  MatGridListModule,
  MatBadgeModule,
  MatSlideToggleModule,
  MatRadioModule,
  MatCheckboxModule,
} from '@angular/material';

const list_Material = [
  MatTreeModule, MatButtonToggleModule, MatCardModule, MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule, MatMenuModule, MatExpansionModule,
  MatGridListModule, MatBadgeModule, MatSlideToggleModule, MatRadioModule, MatCheckboxModule, MatSelectModule, MatFormFieldModule, MatAutocompleteModule, MatInputModule
]

@NgModule({
  declarations: [],
  imports: [
    ...list_Material
  ],
  exports: [
    ...list_Material
  ]
})
export class MaterialModule {
}
