import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';

import { NgbDateMomentAdapter } from './util/datepicker-adapter';


@NgModule({
    providers: [{ provide: NgbDateAdapter, useClass: NgbDateMomentAdapter }],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FmmCmpWebSharedModule {
    static forRoot() {
        return {
            ngModule: FmmCmpWebSharedModule
        };
    }
}
