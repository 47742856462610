import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';

import {FormModel} from '../share/model/FormModel';
import {FormFbKit} from '../share/model/FormFbKit';
import {FormConfirm} from '../share/model/FormConfirm';
import {DialogData} from '../share/dialog-data';
import {TrainStation} from '../share/constants/location.constants';
import {FormRequest} from '../share/model/FormRequest';
import {environment} from '../environments/environment';
import {map} from 'rxjs/operators';

type EntityResponseType = HttpResponse<DialogData>;
type ResponseType = HttpResponse<object>;

@Injectable({providedIn: 'root'})
export class AppService {

    public resourceUrl = environment.SCHEDULE_DOMAIN + '/api/trips';
    public url = environment.SCHEDULE_DOMAIN + '/api';
    public otpUrl = environment.OTP_DOMAIN + '/api';

    constructor(protected http: HttpClient) {
    }

    create(formModel: FormModel): Observable<EntityResponseType> {
        return this.http.post<DialogData>(this.resourceUrl + '/create-multi', formModel, {observe: 'response'});
    }

    createRequest(formModel: FormRequest): Observable<EntityResponseType> {
        return this.http.post<DialogData>(this.resourceUrl + '/create-request', formModel, {observe: 'response'});
    }

    sentRequest(formModel: FormRequest): Observable<EntityResponseType> {
      return this.http.post<DialogData>(this.url + '/req-schedule/sent-request', formModel, {observe: 'response'});
    }

    confirmInfo(params) {
        return this.http.post<FormConfirm>(this.resourceUrl + '/confirm-request-car', params, {observe: 'response'});
    }

    getPayLoadFromToken(params: HttpParams): Observable<ResponseType> {
        return this.http.post<HttpParams>(this.resourceUrl + '/verify-token', {}, {
            observe: 'response',
            params
        });
    }

    checkMobileFacebookKit(formFbKit: FormFbKit): Observable<HttpResponse<FormFbKit>> {
        return this.http.post<FormFbKit>(this.resourceUrl + '/verify-facebook', formFbKit, {observe: 'response'});
    }
    checkMobileOTP(formOTP) {
        return this.http.post(this.otpUrl + '/get-otp-by-mobile', formOTP, {observe: 'response'}).pipe( map((e: any) => (e)));
    }
    confirmOTP(formOTP) {
        return this.http.post(this.otpUrl + '/verify-otp-by-mobile', formOTP, {observe: 'response'}).pipe( map((e: any) => (e)));
    }

    getTrainStation(): Observable<HttpResponse<Array<TrainStation>>> {
        return this.http.get<Array<TrainStation>>(this.url + '/train-station', {observe: 'response'});
    }
}
